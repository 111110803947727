html,
body {
  width: 100%;
  min-height: 100vh;
  background: #fafafa;
}

#root {
  width: 100%;
  min-height: 100vh;
}
